import PzBaseForm from "@/commons/forms/PzBaseForm";
import PzTermsAndConditions from '@/commons/forms/Fields/PzTermsAndConditions';
import PzInputCoint from "@/commons/forms/Fields/PzInputCoin";
import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";
import PzCaptchaButton from "@/commons/forms/Buttons/PzCaptchaButton";
import PzBackButton from "@/commons/forms/Buttons/PzBackButton";
import PzButton from "@/commons/forms/Buttons/PzButton";
import PzLanguage from "@/lang/PzLanguage";
import PzTitle from '@/commons/titles/PzTitle';
import PzLoaderMixins from '@/commons/PzLoaderMixins';
import PzIdentityDocument from '@/commons/forms/Fields/PzIdentityDocument';
import PzDocusingForm from '@/commons/forms/DigitalSings/Docusign/PzDocusingForm';
import PzDocusing from '@/commons/forms/DigitalSings/Docusign/PzDocusign';
import PzYousingForm from '@/commons/forms/DigitalSings/Yousign/PzYousignForm';
import PzYousing from '@/commons/forms/DigitalSings/Yousign/PzYousign';
import PzUpload from '@/commons/forms/Fields/PzUpload';
import PzNaat from '@/commons/forms/DigitalSings/Naat/PzNaat';
import PzNaatForm from '@/commons/forms/DigitalSings/Naat/PzNaatForm';
export default [PzNaatForm, PzNaat, PzUpload, PzDocusing, PzYousing, PzDocusingForm, PzYousingForm, PzIdentityDocument, PzLoaderMixins, PzButton, PzBackButton, PzForwardButton, PzCaptchaButton, PzLanguage, PzBaseForm, PzTitle, PzInputCoint, PzTermsAndConditions];