import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import _objectSpread from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "C:\\xampp\\htdocs\\simulador_new\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "C:\\xampp\\htdocs\\simulador_new\\node_modules\\core-js\\modules\\es.promise.js";
import "C:\\xampp\\htdocs\\simulador_new\\node_modules\\core-js\\modules\\es.object.assign.js";
import "C:\\xampp\\htdocs\\simulador_new\\node_modules\\core-js\\modules\\es.promise.finally.js";
import store from "@/store/index";
import Vue from 'vue';
import VueAxios from "vue-axios";
import axios from 'axios';
import routes from "@/routes/app/routes";
import App from "@/App";
import _i18n from '@/lang/lang_config';
import FlagIcon from 'vue-flag-icon';
import ElementUI from 'element-ui';
import("@/assets/sass/variables_clients/".concat(process.env.VUE_APP_CLIENT, "/index.scss"));
import '@/assets/sass/theme/icons'; // icon

import Vue2Filters from "@/commons/filters/index";
import myModules from "@/commons";
import myComponents from "@/components/Flujo";
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import VueTagManager from "vue-tag-manager";
import Cookies from 'js-cookie';
import VueForceNextTick from 'vue-force-next-tick';
import VueSessionStorage from 'vue-sessionstorage';
import observer from 'vue-mutation-observer';
Vue.use(observer);
Vue.use(VueSessionStorage);
Vue.use(VueForceNextTick); //Google tag manager

Vue.use(VueTagManager, {
  gtmId: process.env.VUE_APP_GTM_KEY
}); //jquery

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $; //frame

import iFrameResize from 'iframe-resizer/js/iframeResizer';
Vue.directive('resize', {
  bind: function bind(el, _ref) {
    var _ref$value = _ref.value,
        value = _ref$value === void 0 ? {} : _ref$value;
    el.addEventListener('load', function () {
      return iFrameResize(value, el);
    });
  }
});
Vue.use(myModules);
Vue.use(myComponents); //ReCaptcha

import VueProgrammaticInvisibleGoogleRecaptcha from 'vue-programmatic-invisible-google-recaptcha';
import { mapGetters } from "vuex";
Vue.component('vue-programmatic-invisible-google-recaptcha', VueProgrammaticInvisibleGoogleRecaptcha);

require('@/commons/utils/fa-icons');

window._ = require("lodash");
Vue.use(VueLodash, {
  name: 'custom',
  lodash: lodash
}); //filters

Vue.use(Vue2Filters); //INTERNACIONALITATION

Vue.use(FlagIcon);
Vue.config.productionTip = false; //AXIOS

Vue.use(VueAxios, axios); //ELEMENT UI

Vue.use(ElementUI, {
  size: Cookies.get('size') || 'medium',
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
}); //MOMENT

var moment = require("moment");

moment.locale('uy');
Vue.use(require("vue-moment"), {
  moment: moment
}); //Phone Number

import PhoneNumber from 'vue-phone-number-input';
Vue.component('phone-number', PhoneNumber);
import money from 'v-money';
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: '',
  suffix: '',
  precision: 0,
  masked: false
});
import VueQrcode from '@chenfengyuan/vue-qrcode';
Vue.component(VueQrcode.name, VueQrcode); //APP

new Vue({
  el: '#app',
  i18n: _i18n,
  components: {
    App: App
  },
  computed: _objectSpread({}, mapGetters(['loader', 'location'])),
  router: routes,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount("#app");
new Vue({
  i18n: _i18n,
  router: routes,
  render: function render(h) {
    return h(App);
  }
});