import _objectSpread from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import PzBackButton from "@/commons/forms/Buttons/PzBackButton";
export default {
  name: 'PzMessageUy',
  components: {
    PzBackButton: PzBackButton
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapGetters(['product', 'form_product', 'current_step'])),
  created: function created() {
    this.$store.dispatch('loader/down', {
      trigger: 'route_enter'
    });
  },
  methods: {
    goFirstStep: function goFirstStep() {
      this.$emit('save');
    }
  }
};