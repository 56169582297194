//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";
import PzBackButton from "@/commons/forms/Buttons/PzBackButton";
import baseVerificacionCliente from "./baseVerificacionCliente";
import { required, EspacioBlanco } from '@/commons/utils/Rules';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
export default {
  name: 'PzVerificacionCliente',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzBackButton: PzBackButton,
    PzForwardButton: PzForwardButton,
    PzIdentityDocument: PzIdentityDocument
  },
  mixins: [baseVerificacionCliente],
  data: function data() {
    return {
      formulario_verificar_cliente: {
        identity: {}
      },
      rules: {
        'identity.document': [required('')],
        'identity.number': [required(''), EspacioBlanco()]
      }
    };
  }
};