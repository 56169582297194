import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.object.values";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { required, type, EspacioBlanco } from "@/commons/utils/Rules";
import { getPaises } from "@/routes/api/resources";
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import { isEmpty } from "@/commons/utils/functions";
import PzPhoneNumber from "@/commons/forms/Fields/PzPhoneNumber";
export default {
  name: 'PzPersonFormEs1',
  components: {
    PzPhoneNumber: PzPhoneNumber,
    PzIdentityDocument: PzIdentityDocument,
    PzFormFlujo: PzFormFlujo
  },
  mixins: [PzBaseForm1],
  data: function data() {
    return {
      marital_status: [],
      paises: [],
      suggest: [],
      formulario1: {
        nombres: '',
        apellidos: '',
        telefono: {},
        pais_nacimiento: '',
        fecha_nacimiento: '',
        mail: '',
        destino_prestamo: '',
        cuota_maxima: '',
        ingreso_mensual: '',
        identity: {}
      },
      rules_formulario1: {
        'identity.document': [required('')],
        'identity.number': [required(''), EspacioBlanco()],
        nombres: [required(''), EspacioBlanco()],
        apellidos: [required(''), EspacioBlanco()],
        fecha_nacimiento: [required('')],
        pais_nacimiento: [required('')],
        mail: [required(''), type({
          field: '',
          type: 'email'
        })],
        cuota_maxima: [required(''), type({
          field: '',
          type: 'number'
        })],
        ingreso_mensual: [required(''), type({
          field: '',
          type: 'number'
        })],
        destino_prestamo: [required('')],
        estado_civil_id: [required('')]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return Date.now() < time.getTime();
        }
      }
    };
  },
  created: function created() {
    this.fetchData();
    this.suggest = Object.values(this.$t('credit_motive')).map(function (value) {
      return {
        value: value
      };
    });
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var paises = getPaises().then(function (res) {
        _this.paises = res.data;
      }).catch(function () {
        _this.paises = [];
      });
      Promise.all([paises]).finally(function () {
        _this.$store.dispatch('loader/down', {
          trigger: _this.$options.name
        });
      });
    },
    getSuggest: function getSuggest(queryString, cb) {
      var suggest = this.suggest;
      var self = this;
      var results = !isEmpty(queryString) && !isEmpty(suggest) ? suggest.filter(function (value) {
        return self._.deburr(value.value).toLowerCase().indexOf(self._.deburr(queryString).toLowerCase()) >= 0;
      }) : suggest;
      cb(results);
    }
  }
};