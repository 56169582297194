var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { attrs: { span: 24 } },
    [
      _c("pz-title", [
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v(" " + _vm._s(_vm.$t("views.final.greetings")) + " ")
        ]),
        _c("span", { attrs: { slot: "sub_title" }, slot: "sub_title" }, [
          _vm._v(" " + _vm._s(_vm.$t("views.final.sent_ok")) + " ")
        ])
      ]),
      _c(
        "el-row",
        { staticClass: "pz-font-size-md", attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { staticClass: "text-center", attrs: { span: 18, offset: 3 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _vm._v(" " + _vm._s(_vm.$t("views.final.data_received")) + " ")
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("views.final.data_received_2")) + " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "row justify-content-center", attrs: { span: 24 } },
            [
              _c("pz-back-button", {
                attrs: {
                  back: _vm.goFirstStep,
                  "button-name": _vm.$t("views.final.back_button")
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }