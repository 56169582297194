import "core-js/modules/es.array.filter";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { isEmpty } from "@/commons/utils/functions";
import { getDocumentsType } from "@/routes/api/resources";
export default {
  name: "PzIdentityDocument",
  components: {},
  props: {
    prop: {
      default: null,
      type: String
    },
    value: {
      type: [Object],
      default: function _default() {
        return {
          document: '',
          number: ''
        };
      }
    },
    typeHidden: {
      type: Boolean,
      default: false
    },
    typeLabel: {
      type: String,
      default: null
    },
    numberLabel: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'person'
    },
    numberHidden: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      listDocuments: [],
      tipo: '',
      numero_documento: '',
      tipo_documento: ''
    };
  },
  watch: {
    value: function value(val) {
      this.setTipoDocumento(val);
      this.setNumeroDocumento(val);
    },
    type: function type(val) {
      this.setTipo(val);
    },
    // eslint-disable-next-line no-unused-vars
    numero_documento: function numero_documento(val) {
      this.handleChange();
    },
    // eslint-disable-next-line no-unused-vars
    tipo_documento: function tipo_documento(val) {
      this.handleChange();
    }
  },
  created: function created() {
    var _this = this;

    this.$store.dispatch('loader/up', {
      trigger: this.$options.name
    });
    this.setTipoDocumento(this.value);
    this.setNumeroDocumento(this.value);
    this.setTipo(this.type);
    var doc = this.documentsType();
    Promise.all([doc]).finally(function () {
      _this.$store.dispatch('loader/down', {
        trigger: _this.$options.name
      });
    });
  },
  methods: {
    handleChange: function handleChange() {
      var value = this.getInputValue();
      var key = this.getInputKey();
      var docs = {
        document: key,
        number: value
      };
      this.$emit('input', docs);
    },
    documentsType: function documentsType() {
      var _this2 = this;

      if (!this.typeHidden) {
        this.$store.dispatch('loader/up', {
          trigger: this.$options.name
        });
        getDocumentsType(this.tipo).then(function (res) {
          _this2.listDocuments = res.data;

          if (_this2.listDocuments.length === 1) {
            _this2.tipo_documento = _this2.listDocuments[0].id;
          } else {
            var self = _this2;
            var found = null;

            if (!isEmpty(_this2.value.document)) {
              found = _this2.listDocuments.filter(function (value) {
                return value.id.toString() === self.value.document.toString();
              });
            }

            if (isEmpty(found)) {
              _this2.tipo_documento = _this2.listDocuments[0].id;
            }
          }

          _this2.handleChange();
        }).catch().finally(function () {
          _this2.$store.dispatch('loader/down', {
            trigger: _this2.$options.name
          });
        });
      }
    },
    getInputKey: function getInputKey() {
      return this.tipo_documento;
    },
    getInputValue: function getInputValue() {
      return this.numero_documento;
    },
    setTipoDocumento: function setTipoDocumento(value) {
      var input = this.getInputValue();
      if (input === value.document) return;
      this.tipo_documento = value.document;
    },
    setNumeroDocumento: function setNumeroDocumento(value) {
      var input = this.getInputKey();
      if (input === value.number) return;
      this.numero_documento = value.number;
    },
    setTipo: function setTipo(value) {
      var input = this.tipo;
      if (input === value) return;
      this.tipo = value;
    }
  }
};