var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-radio-group",
    {
      staticClass: "center-block",
      model: {
        value: _vm.myForm.otras_ofertas.final,
        callback: function($$v) {
          _vm.$set(_vm.myForm.otras_ofertas, "final", $$v)
        },
        expression: "myForm.otras_ofertas.final"
      }
    },
    [
      _c(
        "el-col",
        { staticClass: "pz-large-margin", attrs: { span: 24 } },
        [
          _c(
            "h3",
            { staticClass: "text-center pz-large-margin pz-color-primary" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("forms_flujo.oferta_down_solicitud_default.label_1")
                  ) +
                  " "
              )
            ]
          ),
          _vm._l(_vm.myForm.montos_array, function(value, index) {
            return _c(
              "el-col",
              {
                key: index,
                attrs: {
                  xl: { span: 20, offset: 2 },
                  lg: { span: 20, offset: 2 },
                  md: { span: 20, offset: 2 },
                  sm: { span: 20, offset: 2 },
                  xs: { span: 20, offset: 2 }
                }
              },
              [
                _c(
                  "el-radio",
                  {
                    staticClass: "text-center",
                    staticStyle: { "align-content": "center" },
                    attrs: { label: index }
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "pz-font-size-lg pz-color-primary" },
                      [
                        _c(
                          "span",
                          { staticClass: "pz-font-size-xl pz-color-secondary" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(
                                    value.monto,
                                    _vm.myForm.producto.moneda_prestamo.symbol,
                                    0,
                                    { spaceBetweenAmountAndSymbol: true }
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "forms_flujo.oferta_down_solicitud_default.label_2"
                              )
                            ) +
                            " "
                        ),
                        _c(
                          "span",
                          { staticClass: "pz-font-size-xl pz-color-secondary" },
                          [_vm._v(" " + _vm._s(value.cuota) + " ")]
                        ),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "forms_flujo.oferta_down_solicitud_default.label_3"
                              )
                            ) +
                            " "
                        ),
                        _c(
                          "span",
                          { staticClass: "pz-font-size-xl pz-color-secondary" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(
                                    value.monto_cuota,
                                    _vm.myForm.producto.moneda_prestamo.symbol,
                                    0,
                                    { spaceBetweenAmountAndSymbol: true }
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "el-col",
        { staticClass: "pz-large-margin", attrs: { span: 24 } },
        [
          _c(
            "h3",
            { staticClass: "text-center pz-large-margin pz-color-primary" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("forms_flujo.oferta_down_solicitud_default.label_4")
                  ) +
                  " "
              )
            ]
          ),
          _vm._l(_vm.myForm.cuotas_array, function(value, index) {
            return _c(
              "el-col",
              {
                key: index + 2,
                attrs: {
                  xl: { span: 20, offset: 2 },
                  lg: { span: 20, offset: 2 },
                  md: { span: 20, offset: 2 },
                  sm: { span: 20, offset: 2 },
                  xs: { span: 20, offset: 2 }
                }
              },
              [
                _c(
                  "el-radio",
                  {
                    staticClass: "text-center",
                    staticStyle: { "align-content": "center" },
                    attrs: { label: index + 2 }
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "pz-font-size-lg pz-color-primary" },
                      [
                        _c(
                          "span",
                          { staticClass: "pz-font-size-xl pz-color-secondary" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(
                                    value.monto,
                                    _vm.myForm.producto.moneda_prestamo.symbol,
                                    0,
                                    { spaceBetweenAmountAndSymbol: true }
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "forms_flujo.oferta_down_solicitud_default.label_2"
                              )
                            ) +
                            " "
                        ),
                        _c(
                          "span",
                          { staticClass: "pz-font-size-xl pz-color-secondary" },
                          [_vm._v(" " + _vm._s(value.cuota) + " ")]
                        ),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "forms_flujo.oferta_down_solicitud_default.label_3"
                              )
                            ) +
                            " "
                        ),
                        _c(
                          "span",
                          { staticClass: "pz-font-size-xl pz-color-secondary" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(
                                    value.monto_cuota,
                                    _vm.myForm.producto.moneda_prestamo.symbol,
                                    0,
                                    { spaceBetweenAmountAndSymbol: true }
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }