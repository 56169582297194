//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'PzSimulatorsTemplate',
  components: {},
  inject: ['myForm'],
  provide: function provide() {
    return {
      myForm: this.myForm
    };
  },
  methods: {
    handleBack: function handleBack() {
      return this.myForm.handleBack();
    },
    handleForward: function handleForward() {
      return this.myForm.handleSave();
    }
  }
};