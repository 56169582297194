import _objectSpread from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PzInstantor from "@/commons/forms/Fields/OpenBanking/PzInstantor";
import { getInstantorSettings } from "@/routes/api/resources";
import { mapGetters } from "vuex";
import PzTitle from "@/commons/titles/PzTitle";
import PzButton from "@/commons/forms/Buttons/PzButton";
import { isEmpty } from "@/commons/utils/functions";
export default {
  name: "PzOpenBanking",
  components: {
    PzButton: PzButton,
    PzTitle: PzTitle,
    PzInstantor: PzInstantor
  },
  props: {
    errors: {
      type: String,
      default: null
    },
    rules: {
      type: Object,
      default: null
    },
    prop: {
      type: String,
      default: null
    },
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      statements: [{
        icon: 'locked',
        label: this.$t('commons.open_banking.statements.acces_data')
      }, {
        icon: 'circle-check',
        label: this.$t('commons.open_banking.statements.service')
      }, {
        icon: 'secure',
        label: this.$t('commons.open_banking.statements.save')
      }, {
        icon: 'plain',
        label: this.$t('commons.open_banking.statements.fast')
      }, {
        icon: 'star',
        label: this.$t('commons.open_banking.statements.easy')
      }],
      open_first: true,
      credentials: {},
      centerDialogVisible: false,
      instantor_data: {
        open_banking: false,
        pased: false
      }
    };
  },
  computed: _objectSpread({}, mapGetters(['company', 'alias'])),
  watch: {
    value: function value(_value) {
      this.setValue(_value);
    },
    instantor_data: function instantor_data(value) {
      this.emitData(value);
    }
  },
  created: function created() {
    this.fetchData();
  },
  mounted: function mounted() {},
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleChange: function handleChange(val) {
      this.openDialog(true);
      this.instantor_data.open_banking = this.instantor_data.pased;
      this.emitData(this.instantor_data);
    },
    closeDialog: function closeDialog() {
      this.openDialog(false);
      this.open_first = true;
      this.instantor_data.open_banking = this.instantor_data.pased;
      this.emitData(this.instantor_data);
    },
    setValue: function setValue(value) {
      var input = this.instantor_data;

      if (isEmpty(input)) {
        this.instantor_data = {
          open_banking: false,
          pased: false
        };
      }

      if (input === value) return;
      this.instantor_data = value;
      this.emitData(this.instantor_data);
    },
    openDialog: function openDialog(open) {
      this.centerDialogVisible = open;
    },
    emitData: function emitData(data) {
      this.instantor_data = data;
      this.instantor_data.open_banking = data.pased;
      this.$emit('input', data);
    },
    handleClick: function handleClick() {
      this.open_first = false;
    },
    fetchData: function fetchData() {
      var _this = this;

      getInstantorSettings().then(function (res) {
        _this.credentials = res.data;
      });
    }
  }
};