import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import { isEmpty } from 'element-ui/src/utils/util';
import { getOferta } from '@/routes/api/views/flujo';
import ElOfertaDownSolicitudDefault from "./defaults/partsSolicitud/OfertaDownSolicitudDefault";
import ElOfertaUpSolicitudDefault from "./defaults/partsSolicitud/OfertaUpSolicitudDefault";
import ElOfertaUpDescuentosDefault from "./defaults/partsDescuentos/OfertaUpDescuentosDefault";
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
export default {
  name: 'PzBaseOfertas',
  components: {},
  mixins: [baseFormFlujo],
  provide: function provide() {
    return {
      ofertas: this
    };
  },
  data: function data() {
    return {
      reference: 'otras_ofertas',
      elOfertaView: '',
      best: '',
      montos_array: '',
      cuotas_array: '',
      down: false,
      mycomponents: [ElOfertaUpSolicitudDefault.name, ElOfertaDownSolicitudDefault.name, ElOfertaUpDescuentosDefault.name]
    };
  },
  created: function created() {
    var _this = this;

    this.$store.dispatch('loader/up', {
      trigger: this.$options.name
    });

    if (this.producto.needsDocuments) {
      this.getOfertaView('descuentos');
    } else {
      this.getOfertaView('solicitud');
    }

    getOferta(this.loan, {
      withAdditionals: this.extraData.withAdditionals
    }).then(function (res) {
      _this.best = res.data;

      if (!isEmpty(res.data.adicionales)) {
        _this.cuotas_array = res.data.adicionales.cuotas;
        _this.montos_array = res.data.adicionales.montos;
        _this.down = _this.extraData.withAdditionals;
      }
    }).catch(function () {
      _this.$router.push({
        name: 'Rejected'
      });
    }).finally(function () {
      _this.$store.dispatch('loader/down', {
        trigger: _this.$options.name
      });
    });
  },
  methods: {
    getOfertaView: function getOfertaView(tipe) {
      this.elOfertaView = tipe + '-' + this.extraData.ofertaView;
    }
  }
};