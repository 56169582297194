import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.iterator";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getPaises } from "@/routes/api/resources";
import { arrayFind, isEmpty, isString, isNumber } from "@/commons/utils/functions";
export default {
  name: "PzPhoneNumber",
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: function _default() {
        return {
          code: '',
          numero: ''
        };
      }
    },
    label: {
      type: [String, Number],
      default: null
    },
    error: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledCountrySelector: {
      type: Boolean,
      default: false
    },
    defaultCountryCode: {
      type: String,
      default: null
    },
    noFlags: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    noCountrySelector: {
      type: Boolean,
      default: false
    },
    showCodeOnList: {
      type: Boolean,
      default: false
    },
    onlyCountries: {
      type: Array,
      default: null
    },
    ignoredCountries: {
      type: Array,
      default: Array
    },
    noValidatorState: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    var _this = this;

    var checkPhoneNumber = function checkPhoneNumber(rule, value, callback) {
      if (isEmpty(_this.result) || isEmpty(_this.result.numero) && isEmpty(_this.result.numero)) {
        return callback(new Error(_this.$t('rules.field_mandatory', {
          field: _this.$t('')
        })));
      }

      if (isEmpty(_this.result.numero)) {
        return callback(new Error(_this.$t('rules.field_mandatory', {
          field: _this.$t('rules.country_code')
        })));
      }

      if (isEmpty(_this.result.code) && !_this.noCountrySelector) {
        return callback(new Error(_this.$t('rules.field_mandatory'), {
          field: _this.$t('rules.country_code')
        }));
      }

      return callback();
    };

    var checkNumber = function checkNumber(rule, value, callback) {
      if (!isEmpty(_this.result.numero) && !isNumber(_this.result.numero)) {
        return callback(new Error(_this.$t('rules.field_number', {
          field: _this.$t('')
        })));
      }

      return callback();
    };

    return {
      countries: [],
      filteredOptions: [],
      countryIso: '',
      result: {
        code: '',
        numero: ''
      },
      rule: {
        phone: [{
          validator: checkPhoneNumber,
          trigger: 'change'
        }, {
          validator: checkNumber,
          trigger: 'change'
        }],
        number: [{
          validator: checkNumber,
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    countryIso: function countryIso(newValue, oldValue) {
      if (newValue === oldValue) return;
      var pais = this.getCode(newValue);
      this.result.code = pais.calling_code;
      this.emitValues(this.result);
    },
    value: function value(newValue) {
      this.setValue(newValue);
    },
    defaultCountryCode: function defaultCountryCode(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.setLocale(newValue);
    },
    result: function result(value) {
      this.emitValues(value);
    }
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleChange: function handleChange(val) {
      this.filteredOptions = this.countries;
    },
    setValue: function setValue(newValue) {
      if (newValue === this.result || isEmpty(newValue)) return;

      if (isString(newValue) || isNumber(newValue)) {
        this.result.numero = newValue;
      } else {
        this.result = newValue;

        if (!isEmpty(this.result.code)) {
          var pais = this.getIso(newValue.code);
          this.countryIso = pais ? pais.iso : null;
        }
      }
    },
    setLocale: function setLocale(locale) {
      if (locale) {
        var pais = this.getIso(locale);
        this.countryIso = pais.iso;
        this.result.code = locale;
        this.emitValues(this.result);
      }
    },
    emitValues: function emitValues(payload) {
      this.$emit('input', payload);
    },
    filterMethod: function filterMethod(query) {
      query = query.replace('+', '');
      this.filteredOptions = this.countries.filter(function (option) {
        return option.nombre.toString().toLowerCase().indexOf(query.toLowerCase()) > -1 || option.calling_code.toString().indexOf(query) > -1;
      });
      return this.filteredOptions;
    },
    getCode: function getCode(iso) {
      return arrayFind(this.countries, function (elem) {
        return elem.iso.toString().toLowerCase() === iso.toString().toLowerCase();
      });
    },
    getIso: function getIso(code) {
      code = code.replace('+', '');
      return arrayFind(this.countries, function (elem) {
        return elem.calling_code.toString() === code.toString();
      });
    },
    fetchData: function fetchData() {
      var _this2 = this;

      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var countries = getPaises().then(function (res) {
        _this2.filteredOptions = _this2.countries = res.data;

        _this2.setValue(_this2.value);

        if (isEmpty(_this2.result.code) && !_this2.defaultCountryCode) {
          console.log('current country code');

          _this2.$store.dispatch('app/getCountryFrom', _this2.countries).then(function (pais) {
            if (pais) {
              _this2.countryCode = pais.iso;
            }
          });
        } else if (isEmpty(_this2.result.code)) {
          console.log('country default code');

          _this2.setLocale(_this2.defaultCountryCode);
        }
      }).catch(function () {
        _this2.countryCode = '';
      });
      Promise.all([countries]).finally(function () {
        _this2.$store.dispatch('loader/down', {
          trigger: _this2.$options.name
        });
      });
    }
  }
};