import request from "@/routes/api/api_conextion";
export function resendCodeDocSign(params) {
  return request({
    url: "/vale/code/reset",
    method: 'post',
    data: params
  });
}
export function getDocToSign(params) {
  return request({
    url: "/vale/code/route",
    method: 'post',
    data: params
  });
}
export function setDocSign(alias, envelop, event) {
  return request({
    url: '/vale/sing/' + alias + '/' + envelop,
    method: 'post',
    data: event
  });
}
export function getDocsSign(params) {
  return request({
    url: "/vale/docs/sing",
    method: 'post',
    data: params
  });
}