var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-col",
        {
          attrs: {
            xl: { span: 12, offset: 6 },
            lg: { span: 12, offset: 6 },
            md: { span: 12, offset: 6 },
            sm: { span: 24 },
            xs: { span: 24 }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: _vm.prop, rules: _vm.rules, error: _vm.errors } },
            [
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.instantor_data.open_banking,
                    callback: function($$v) {
                      _vm.$set(_vm.instantor_data, "open_banking", $$v)
                    },
                    expression: "instantor_data.open_banking"
                  }
                },
                [
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.label
                            ? _vm.label
                            : _vm.$t("commons.open_banking.label")
                        ) +
                        " "
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 24 } },
        [
          _c(
            "el-row",
            { staticClass: "row justify-content-center pz-margin-bottom-10" },
            [
              _c("pz-svg-icon", {
                attrs: { "class-name": "logo2", "icon-class": "instantor" },
                on: {
                  click: function($event) {
                    return _vm.handleChange(true)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row justify-content-center" },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _vm._t("clarifyingText", [
                    _c(
                      "i18n",
                      {
                        attrs: { path: "commons.open_banking.text", tag: "p" }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              place: "instanor_link",
                              href: "https://www.instantor.com/",
                              target: "_blank"
                            }
                          },
                          [_vm._v(" Instantor AB")]
                        ),
                        _c(
                          "b",
                          {
                            staticClass: "pz-color-primary",
                            attrs: { place: "company" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("capitalize")(_vm.company || _vm.alias, {
                                  onlyFirstLetter: false
                                })
                              )
                            )
                          ]
                        )
                      ]
                    )
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("pz-svg-icon", {
                        staticClass: "secure-icon big",
                        attrs: { "icon-class": "secure" }
                      }),
                      _c("span", { staticStyle: { "vertical-align": "top" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("commons.open_banking.not_keep_pasword")
                            )
                        )
                      ]),
                      _c("br")
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticClass: "pz-dialog-banking",
              attrs: {
                title: _vm._f("capitalize")(
                  _vm.$t("commons.open_banking.title_header"),
                  { onlyFirstLetter: true }
                ),
                visible: _vm.centerDialogVisible,
                center: ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.centerDialogVisible = $event
                }
              }
            },
            [
              _vm.open_first
                ? _c(
                    "el-row",
                    { staticClass: "row justify-content-center" },
                    [
                      _c("pz-title", [
                        _c(
                          "span",
                          {
                            staticClass: "pz-font-size-md",
                            attrs: { slot: "title" },
                            slot: "title"
                          },
                          [_vm._v(_vm._s(_vm.$t("commons.open_banking.title")))]
                        )
                      ]),
                      _c("el-row", [
                        _c(
                          "ul",
                          { staticClass: "statement-list" },
                          _vm._l(_vm.statements, function(statement, index) {
                            return _c(
                              "li",
                              { key: index },
                              [
                                _c("pz-svg-icon", {
                                  attrs: {
                                    "class-name":
                                      "pz-font-size-md  pz-color-info li-icons",
                                    "icon-class": statement.icon
                                  }
                                }),
                                _vm._v(" " + _vm._s(statement.label) + " ")
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ]),
                      _c(
                        "el-col",
                        { staticClass: "row justify-content-center" },
                        [
                          _c("pz-svg-icon", {
                            attrs: {
                              "class-name": "logo",
                              "icon-class": "instantor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "row justify-content-center",
                          attrs: { span: 24 }
                        },
                        [
                          _c("pz-button", {
                            attrs: {
                              type: "primary",
                              "button-name": _vm.$t("continue")
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleClick()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c("pz-instantor", {
                    attrs: { credentials: _vm.credentials },
                    on: {
                      close: function($event) {
                        return _vm.closeDialog()
                      }
                    },
                    model: {
                      value: _vm.instantor_data,
                      callback: function($$v) {
                        _vm.instantor_data = $$v
                      },
                      expression: "instantor_data"
                    }
                  })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }