var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-form-flujo",
    {
      attrs: {
        "available-captcha": _vm.extraData.withCaptcha,
        forward: _vm.handleSave,
        backwards: _vm.showBack ? _vm.handleBack : null
      },
      model: {
        value: _vm.formulario_verificar_cliente["captcha"],
        callback: function($$v) {
          _vm.$set(_vm.formulario_verificar_cliente, "captcha", $$v)
        },
        expression: "formulario_verificar_cliente['captcha']"
      }
    },
    [
      _c("span", { attrs: { slot: "form_title" }, slot: "form_title" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("forms_flujo.verificacion_cliente.title")) + " "
        )
      ]),
      _c(
        "el-form",
        {
          ref: _vm.reference,
          attrs: {
            slot: "formulario",
            model: _vm.formulario_verificar_cliente,
            "status-icon": "",
            rules: _vm.rules
          },
          slot: "formulario"
        },
        [
          _c(
            "el-col",
            {
              attrs: {
                xl: { span: 12, offset: 6 },
                lg: { span: 12, offset: 6 },
                md: { span: 12, offset: 6 },
                sm: { span: 24 },
                xs: { span: 24 }
              }
            },
            [
              _c("pz-identity-document", {
                ref: "identity",
                attrs: {
                  prop: "identity",
                  type: "person",
                  "type-label": _vm.$t("forms_flujo.form1_persona.SP.tipo_doc"),
                  "number-label": _vm.$t(
                    "forms_flujo.form1_persona.SP.identity_doc"
                  )
                },
                model: {
                  value: _vm.formulario_verificar_cliente.identity,
                  callback: function($$v) {
                    _vm.$set(_vm.formulario_verificar_cliente, "identity", $$v)
                  },
                  expression: "formulario_verificar_cliente.identity"
                }
              })
            ],
            1
          ),
          _c("br"),
          _c(
            "el-col",
            { staticClass: "center-block text-center", attrs: { span: 24 } },
            [
              _c("span", { staticStyle: { color: "#db996c" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("forms_flujo.pz_simulators_template.warning_bnf")
                    ) +
                    " "
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "el-col",
        {
          staticClass: "center-block text-center",
          attrs: { slot: "buttons", span: 24 },
          slot: "buttons"
        },
        [
          _vm.showBack
            ? _c("pz-back-button", { attrs: { back: _vm.handleBack } })
            : _vm._e(),
          _vm.extraData.withCaptcha
            ? _c("pz-captcha-button", {
                attrs: { forward: _vm.handleSave },
                model: {
                  value: _vm.formulario_verificar_cliente["captcha"],
                  callback: function($$v) {
                    _vm.$set(_vm.formulario_verificar_cliente, "captcha", $$v)
                  },
                  expression: "formulario_verificar_cliente['captcha']"
                }
              })
            : _c("pz-forward-button", {
                attrs: { forward: _vm.handleSave },
                model: {
                  value: _vm.formulario_verificar_cliente["captcha"],
                  callback: function($$v) {
                    _vm.$set(_vm.formulario_verificar_cliente, "captcha", $$v)
                  },
                  expression: "formulario_verificar_cliente['captcha']"
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }