import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.url";
import _objectSpread from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import { isMobile } from '@/commons/utils/functions';
import VueQrcode from '@chenfengyuan/vue-qrcode';
export default {
  name: "PzNaat",
  components: {
    VueQrcode: VueQrcode
  },
  props: {
    route: {
      require: true,
      default: null,
      type: [String, URL]
    },
    redirect: {
      require: true,
      default: 'Prestamos',
      type: [String]
    }
  },
  data: function data() {
    return {
      frame_route: this.route,
      height: '600px',
      watch: {
        route: function route(value) {
          this.frame_route = value;
        },
        frame_route: function frame_route(value) {
          console.log('change form route');
          console.log(value);
        }
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['alias'])), {}, {
    esmobil: function esmobil() {
      return isMobile();
    }
  }),
  created: function created() {
    window.addEventListener("message", function (event) {
      console.log(event);
      console.log(event.origin);
      var URL_FAD = 'https://uatwebfad4.firmaautografa.com/main';

      if (event.origin == URL_FAD) {
        if (event.data.event === 'PROCESS_STARTED') {
          console.log('proceso iniciado');
        } else if (event.data.event === 'PROCESS_ENDED') {
          console.log('proceso terminado');
        } else if (event.data.event === 'SIGNATURE_COMPLETED') {
          console.log('proceso de firma completo');
        } else if (event.data.event === 'RELOAD_PROCESS') {
          console.log('reinicia el proceso');
        }
      } else {
        return;
      }
    }, false);
  },
  methods: {}
};