import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.entries";
import "core-js/modules/web.dom-collections.for-each";
import _slicedToArray from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/slicedToArray";
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "PzInstantor",
  components: {},
  props: {
    credentials: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      loading: true,
      data: null
    };
  },
  watch: {
    credentials: function credentials(value) {
      this.setInstantor(value);
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.setInstantor(this.credentials);
  },
  update: function update() {
    console.log('update');
  },
  methods: {
    setInstantor: function setInstantor(credencials) {
      var instantor = new window.Instantor(credencials.instance);
      Object.entries(credencials.credentials).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];

        instantor.userParam(key, value);
      });
      instantor.load('#itor');
      var self = this;
      instantor.attachEventListener('load', function () {
        self.loading = false;
      });
      instantor.attachEventListener("chooseBank", function (data) {
        data['identifier'] = instantor.get_request_identifier();
        self.data = data;
        self.data['pased'] = false;
      });
      instantor.listener(function (response) {
        console.log(response);

        switch (response) {
          case 'process-finished':
            self.$emit('close');
            self.data['pased'] = true;
            self.$emit('input', self.data);
            break;

          case 'invalid-login':
            break;

          default:
            /* Process encountered an error. */
            break;
        }
      });
    }
  }
};