var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-simulators-template",
    [
      _c(
        "el-form",
        {
          ref: _vm.reference,
          staticClass: "slider_simulator sliderexterno",
          attrs: {
            slot: "simulador",
            "status-icon": "",
            model: _vm.form,
            rules: _vm.validation
          },
          slot: "simulador"
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-bottom": "0 !important" },
              attrs: { prop: "monto", error: _vm.errors.get("monto") }
            },
            [
              _c("span", { staticClass: "pz-font-size-md" }, [
                _vm._v(" " + _vm._s(_vm.monto_label) + " ")
              ]),
              _c("el-slider", {
                staticClass: "sliderexterno",
                attrs: {
                  "show-input-controls": false,
                  "tooltip-class": "colorToltip",
                  "format-tooltip": _vm.formatTooltip,
                  min: _vm.producto.min_monto,
                  max: _vm.producto.max_monto,
                  step: _vm.producto.step_monto
                },
                on: {
                  change: function($event) {
                    return _vm.$nextTick(_vm.emitChange)
                  },
                  input: _vm.handleInput
                },
                model: {
                  value: _vm.form.monto,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "monto", $$v)
                  },
                  expression: "form.monto"
                }
              }),
              _c(
                "div",
                [
                  _c(
                    "el-col",
                    { staticClass: "pz-font-size-bs", attrs: { span: 12 } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("currency")(
                              _vm.producto.min_monto,
                              _vm.producto.moneda_solicitud.symbol,
                              0,
                              { spaceBetweenAmountAndSymbol: true }
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "pz-font-size-bs text-right",
                      attrs: { span: 12 }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("currency")(
                              _vm.producto.max_monto,
                              _vm.producto.moneda_solicitud.symbol,
                              0,
                              { spaceBetweenAmountAndSymbol: true }
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          parseInt(_vm.producto.min_cuota) !== parseInt(_vm.producto.max_cuota)
            ? _c(
                "el-form-item",
                { attrs: { prop: "plazo", error: _vm.errors.get("plazo") } },
                [
                  _c("span", { staticClass: "pz-font-size-md" }, [
                    _vm._v(
                      _vm._s(_vm.$t("forms_flujo.PzSimulator.how_many_fee"))
                    )
                  ]),
                  _c("el-slider", {
                    staticClass: "sliderexterno",
                    attrs: {
                      min: _vm.producto.min_cuota,
                      max: _vm.producto.max_cuota,
                      step: _vm.producto.step_cuota,
                      "show-input-controls": false
                    },
                    on: {
                      change: function($event) {
                        return _vm.$nextTick(_vm.emitChange)
                      },
                      input: _vm.handleInput
                    },
                    model: {
                      value: _vm.form.plazo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "plazo", $$v)
                      },
                      expression: "form.plazo"
                    }
                  }),
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "pz-font-size-bs", attrs: { span: 12 } },
                        [_vm._v(" " + _vm._s(_vm.producto.min_cuota) + " ")]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "pz-font-size-bs text-right",
                          attrs: { span: 12 }
                        },
                        [_vm._v(" " + _vm._s(_vm.producto.max_cuota) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm.producto.needsSelds
                ? _c(
                    "el-col",
                    {
                      attrs: {
                        xl: { span: 12 },
                        lg: { span: 12 },
                        md: { span: 12 },
                        sm: { span: 24 },
                        xs: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "trabajadores",
                            error: _vm.errors.get("trabajadores")
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("forms_flujo.PzSimulator.annual_sells")
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "Ventas anuales" },
                              on: {
                                change: function($event) {
                                  return _vm.$nextTick(_vm.emitChange)
                                },
                                input: _vm.handleInput
                              },
                              model: {
                                value: _vm.form.trabajadores,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "trabajadores", $$v)
                                },
                                expression: "form.trabajadores"
                              }
                            },
                            _vm._l(_vm.workers, function(worker) {
                              return _c("el-option", {
                                key: worker.id,
                                attrs: {
                                  label: worker.workwers_range,
                                  value: worker.id,
                                  "value-key": worker.id
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.producto.needsFirstDate
                ? _c(
                    "el-col",
                    {
                      attrs: {
                        xl: { span: 6 },
                        lg: { span: 6 },
                        md: { span: 6 },
                        sm: { span: 12 },
                        xs: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "bordered",
                          attrs: {
                            prop: "fecha_vencimiento",
                            error: _vm.errors.get("fecha_vencimiento")
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.fechapago_label) + " ")
                          ]),
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              span: 2,
                              placeholder: _vm.$t(
                                "forms_flujo.PzSimulator.select_date"
                              ),
                              "picker-options": _vm.pickerOptions,
                              format: "dd-MM-yyyy",
                              "value-format": "dd-MM-yyyy"
                            },
                            on: {
                              change: function($event) {
                                return _vm.$nextTick(_vm.emitChange)
                              },
                              input: function($event) {
                                return _vm.$nextTick(_vm.handleInput)
                              }
                            },
                            model: {
                              value: _vm.form.fecha_vencimiento,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "fecha_vencimiento", $$v)
                              },
                              expression: "form.fecha_vencimiento"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.producto.needsFirstDate
                ? _c(
                    "el-col",
                    {
                      attrs: {
                        xl: { span: 6 },
                        lg: { span: 6 },
                        md: { span: 6 },
                        sm: { span: 12 },
                        xs: { span: 24 }
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "emision",
                            error: _vm.errors.get("emision")
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "forms_flujo.PzSimulator.emission_date"
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              span: 2,
                              placeholder: _vm.$t(
                                "forms_flujo.PzSimulator.select_date"
                              ),
                              format: "dd-MM-yyyy",
                              "picker-options": _vm.pickerOptionsEmision,
                              "value-format": "dd-MM-yyyy"
                            },
                            on: {
                              change: function($event) {
                                return _vm.$nextTick(_vm.emitChange)
                              },
                              input: function($event) {
                                return _vm.$nextTick(_vm.handleInput)
                              }
                            },
                            model: {
                              value: _vm.form.emision,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "emision", $$v)
                              },
                              expression: "form.emision"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("pz-oferta-" + _vm.elOfertaView, { tag: "comment" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }