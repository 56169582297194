var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.esmobil
    ? _c("iframe", {
        staticClass: "pz-large-margin",
        attrs: {
          id: "naat",
          frameborder: "0",
          width: "100%",
          height: _vm.height,
          src: _vm.frame_route,
          sandbox:
            "allow-forms allow-modals allow-popups allow-same-origin allow-scripts",
          allow: "geolocation; microphone; camera;"
        }
      })
    : _c(
        "el-col",
        { staticClass: "pz-large-margin text-lg-center" },
        [
          _c("pz-title", [
            _c("span", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v(
                " Escanear Codigo QR para continuar el proceso en su Mobile "
              ),
              _c("br")
            ])
          ]),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 24 },
                    lg: { span: 24 },
                    md: { span: 24 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c("vue-qrcode", {
                    attrs: { value: _vm.frame_route, options: { width: 200 } }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }