var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-form-flujo",
    {
      attrs: {
        "available-captcha": _vm.extraData.withCaptcha,
        forward: _vm.handleSave,
        backwards: _vm.showBack ? _vm.handleBack : null
      },
      model: {
        value: _vm.formulario1["captcha"],
        callback: function($$v) {
          _vm.$set(_vm.formulario1, "captcha", $$v)
        },
        expression: "formulario1['captcha']"
      }
    },
    [
      _c("span", { attrs: { slot: "form_title" }, slot: "form_title" }, [
        _vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.SP.title")) + " ")
      ]),
      _c(
        "el-form",
        {
          ref: _vm.reference,
          attrs: {
            slot: "formulario",
            "label-position": "top",
            model: _vm.formulario1,
            "status-icon": "",
            rules: _vm.rules_formulario1
          },
          slot: "formulario"
        },
        [
          _c("pz-direction", {
            attrs: { "ask-postal-code": "" },
            model: {
              value: _vm.formulario1.direccion,
              callback: function($$v) {
                _vm.$set(_vm.formulario1, "direccion", $$v)
              },
              expression: "formulario1.direccion"
            }
          }),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "estado_civil_id",
                        label: _vm.$t(
                          "forms_flujo.form1_persona.SP.civil_state"
                        ),
                        error: _vm.errors.get("estado_civil_id")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "estado_civil_id",
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t(
                              "forms_flujo.form1_persona.SP.civil_state"
                            )
                          },
                          model: {
                            value: _vm.formulario1.estado_civil_id,
                            callback: function($$v) {
                              _vm.$set(_vm.formulario1, "estado_civil_id", $$v)
                            },
                            expression: "formulario1.estado_civil_id"
                          }
                        },
                        _vm._l(_vm.marital_status, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.estado,
                              value: item.id,
                              placeholder: _vm.$t(
                                "forms_flujo.form1_persona.SP.civil_state"
                              )
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "genero",
                        label: _vm.$t("forms_flujo.form1_persona.SP.genero"),
                        error: _vm.errors.get("genero")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "genero",
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t(
                              "forms_flujo.form1_persona.SP.genero"
                            )
                          },
                          model: {
                            value: _vm.formulario1.genero,
                            callback: function($$v) {
                              _vm.$set(_vm.formulario1, "genero", $$v)
                            },
                            expression: "formulario1.genero"
                          }
                        },
                        _vm._l(_vm.genero, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.name,
                              value: item.id,
                              placeholder: _vm.$t(
                                "forms_flujo.form1_persona.SP.genero"
                              )
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "entidad_nacimiento",
                        label: _vm.$t(
                          "forms_flujo.form1_persona.SP.entidad_nacimiento"
                        ),
                        error: _vm.errors.get("entidad_nacimiento")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "entidad_nacimiento",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form1_persona.SP.entidad_nacimiento"
                          )
                        },
                        model: {
                          value: _vm.formulario1.entidad_nacimiento,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario1,
                              "entidad_nacimiento",
                              _vm._n($$v)
                            )
                          },
                          expression: "formulario1.entidad_nacimiento"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "info_extra.carnet_conducir",
                        label: _vm.$t(
                          "forms_flujo.form1_persona.SP.tiene_carnet_conducir"
                        ),
                        error: _vm.errors.get("carnet_conducir")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "info_extra.carnet_conducir",
                          model: {
                            value: _vm.formulario1.info_extra.carnet_conducir,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formulario1.info_extra,
                                "carnet_conducir",
                                $$v
                              )
                            },
                            expression: "formulario1.info_extra.carnet_conducir"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { value: 1, label: _vm.$t("1") }
                          }),
                          _c("el-option", {
                            attrs: { value: 0, label: _vm.$t("0") }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "estado_civil_id",
                        label: _vm.$t(
                          "forms_flujo.form1_persona.SP.banco_deposito"
                        ),
                        error: _vm.errors.get("banco_deposito")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "estado_civil_id",
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t(
                              "forms_flujo.form1_persona.SP.banco_deposito"
                            )
                          },
                          model: {
                            value: _vm.formulario1.banco_deposito,
                            callback: function($$v) {
                              _vm.$set(_vm.formulario1, "banco_deposito", $$v)
                            },
                            expression: "formulario1.banco_deposito"
                          }
                        },
                        _vm._l(_vm.bancos, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.short_name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "banco",
                        label: _vm.$t(
                          "forms_flujo.form1_persona.SP.cuenta_deposito"
                        ),
                        error: _vm.errors.get("cuenta_deposito")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "banco",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form1_persona.SP.cuenta_deposito"
                          )
                        },
                        model: {
                          value: _vm.formulario1.cuenta_deposito,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formulario1,
                              "cuenta_deposito",
                              _vm._n($$v)
                            )
                          },
                          expression: "formulario1.cuenta_deposito"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20, type: "flex", justify: "center" } },
            [
              _c("pz-open-banking", {
                attrs: {
                  prop: "bank_data",
                  label: _vm.$t("forms_flujo.form1_persona.SP.comprobacion_sc")
                },
                model: {
                  value: _vm.formulario1.instantor,
                  callback: function($$v) {
                    _vm.$set(_vm.formulario1, "instantor", $$v)
                  },
                  expression: "formulario1.instantor"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }