var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          !_vm.typeHidden
            ? _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 8 },
                    lg: { span: 8 },
                    md: { span: 8 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.typeLabel
                          ? _vm.typeLabel
                          : _vm.$t("forms_flujo.form1_persona.SP.tipo_doc"),
                        prop: _vm.prop + ".document"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "document",
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: _vm.typeLabel
                              ? _vm.typeLabel
                              : _vm.$t("forms_flujo.form1_persona.SP.tipo_doc"),
                            disabled: _vm.numberHidden ? "disabled" : false
                          },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.tipo_documento,
                            callback: function($$v) {
                              _vm.tipo_documento = $$v
                            },
                            expression: "tipo_documento"
                          }
                        },
                        _vm._l(_vm.listDocuments, function(item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: { label: item.documento, value: item.id }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.documento + " ")),
                                _c(
                                  "span",
                                  { staticStyle: { color: "#8492a6" } },
                                  [_vm._v(_vm._s(item.nombre))]
                                )
                              ])
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            {
              attrs: {
                xl: { span: _vm.typeHidden ? 24 : 16 },
                lg: { span: _vm.typeHidden ? 24 : 16 },
                md: { span: _vm.typeHidden ? 24 : 16 },
                sm: { span: 24 },
                xs: { span: 24 }
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.numberLabel
                      ? _vm.numberLabel
                      : _vm.$t("forms_flujo.form1_persona.SP.identity_doc"),
                    prop: _vm.prop + ".number"
                  }
                },
                [
                  _c("el-input", {
                    ref: "number",
                    attrs: {
                      disabled: _vm.numberHidden,
                      type: "text",
                      placeholder: _vm.numberLabel
                        ? _vm.numberLabel
                        : _vm.$t("forms_flujo.form1_persona.SP.identity_doc")
                    },
                    on: { input: _vm.handleChange },
                    model: {
                      value: _vm.numero_documento,
                      callback: function($$v) {
                        _vm.numero_documento = $$v
                      },
                      expression: "numero_documento"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }