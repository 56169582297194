import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.object.values";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
import _objectSpread from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//import {required, type} from "@/commons/utils/Rules";
import { isEmpty } from "@/commons/utils/functions";
import { getEstudios, getFormasDeEnterarse, getFormasPago, getPais, getRelacionesLaborales, getTipoViviendas } from "@/routes/api/resources";
import baseform2 from '../baseForm2';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import lowercase from "@/commons/filters/string/lowercase";
import { mapGetters } from "vuex";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import { required } from "@/commons/utils/Rules";
export default {
  name: 'PzPersonFormExtEs',
  components: {
    PzIdentityDocument: PzIdentityDocument,
    PzFormFlujo: PzFormFlujo
  },
  mixins: [baseform2],
  data: function data() {
    return {
      employment_relations: [],
      studies: [],
      suggest: [],
      income_source: [{
        id: 'Ama de casa',
        name: 'Ama de Casa'
      }, {
        id: 'Estudiante ',
        name: 'Estudiante'
      }, {
        id: 'Empleado de la ONCE ',
        name: 'Empleado de la ONCE'
      }, {
        id: 'Venta ambulante',
        name: 'Venta ambulante'
      }, {
        id: 'Empleado sector agrario',
        name: 'Empleado sector agrario'
      }, {
        id: 'Contrato fijo (público)',
        name: 'Contrato fijo (público)'
      }, {
        id: 'Contrato temporal (público)',
        name: 'Contrato temporal (público)'
      }, {
        id: 'Contrato fijo (privado)',
        name: 'Contrato fijo (privado)'
      }, {
        id: 'Contrato temporal (privado)',
        name: 'Contrato temporal (privado)'
      }, {
        id: 'Autónomo',
        name: 'Autónomo'
      }, {
        id: 'Prejubilado',
        name: 'Prejubilado'
      }, {
        id: 'Jubilado',
        name: 'Jubilado'
      }, {
        id: 'Pensionista',
        name: 'Pensionista'
      }, {
        id: 'Desempleado con prestación',
        name: 'Desempleado con prestación'
      }, {
        id: 'Desempleado sin prestación',
        name: 'Desempleado sin prestación'
      }],
      cahs_in_list: [],
      vnz: false,
      properties: [],
      formas_de_enterarse: [],
      formulario2: {
        tipo_vivienda: '',
        relacion_laboral_id: '',
        estudios: '',
        cash_in_id: '',
        comoseentero: '',
        persona_contacto: '',
        telefono_persona_contacto: '',
        identity: {},
        info_extra: {
          institucion_de_estudio: '',
          fuente_ingreso_principal: '',
          ultima_direccion_venezuela: '',
          ultimo_trabajo_venezuela: '',
          has_others_credits: false,
          accepted_before: false,
          referenciado_por: ''
        }
      },
      disable: true,
      rules_formulario2: {
        tipo_vivienda: [required('')],
        relacion_laboral_id: [required('')],
        comoseentero: [required('')],
        persona_contacto: [required('')],
        telefono_persona_contacto: [required('')],
        info_extra: {
          referenciado_por: [required('')]
        }
      }
    };
  },
  computed: _objectSpread({}, mapGetters(['applicant'])),
  created: function created() {
    var _this = this;

    this.$store.dispatch('loader/up', {
      trigger: this.$options.name
    });
    this.suggest = Object.values(this.$t('vnz_universities')).map(function (value) {
      return {
        value: value
      };
    }); // this.income_source = Object.values(this.$t('income_source')).map((value) => {
    //     return {value: value}
    // })

    this.fechtData().finally(function () {
      _this.$store.dispatch('loader/down', {
        trigger: _this.$options.name
      });
    });
  },
  methods: {
    getIncomen: function getIncomen(queryString, cb) {
      var suggest = this.income_source;
      var self = this;
      var results = !isEmpty(queryString) && !isEmpty(suggest) ? suggest.filter(function (value) {
        return self._.deburr(value.value).toLowerCase().indexOf(self._.deburr(queryString).toLowerCase()) >= 0;
      }) : suggest;
      cb(results);
    },
    getSuggest: function getSuggest(queryString, cb) {
      var suggest = this.suggest;
      var self = this;
      var results = !isEmpty(queryString) && !isEmpty(suggest) ? suggest.filter(function (value) {
        return self._.deburr(value.value).toLowerCase().indexOf(self._.deburr(queryString).toLowerCase()) >= 0;
      }) : suggest;
      cb(results);
    },
    fechtData: function fechtData() {
      var _this2 = this;

      var viv = getTipoViviendas().then(function (res) {
        _this2.properties = res.data;
      }).catch(function () {
        _this2.properties = [];
      });
      var est = getEstudios().then(function (res) {
        _this2.studies = res.data;
      }).catch(function () {
        _this2.studies = [];
      });
      var re = getRelacionesLaborales().then(function (res) {
        _this2.employment_relations = res.data;
      }).catch(function () {
        _this2.employment_relations = [];
      });
      var form = getFormasDeEnterarse().then(function (res) {
        _this2.formas_de_enterarse = res.data;
      }).catch(function () {
        _this2.formas_de_enterarse = [];
      });
      var cahs_in_list = getFormasPago().then(function (res) {
        _this2.cahs_in_list = res.data;
      }).catch(function () {
        _this2.cahs_in_list = [];
      });
      var country = getPais(this.applicant.pais_nacimiento).then(function (res) {
        if (lowercase(res.data.iso).toString() === 've'.toString()) {
          _this2.vnz = true;
          _this2.formulario2.identity['document'] = 4;
        }
      }).catch(function (error) {
        _this2.vnz = false;
        console.log(error);
      });
      return Promise.all([viv, re, form, est, country, cahs_in_list]);
    }
  }
};