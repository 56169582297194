import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
import _objectSpread from "C:/xampp/htdocs/simulador_new/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getBancos, getEstadoCivil } from "@/routes/api/resources";
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzDirection from "@/commons/forms/Fields/PzDirection";
import PzOpenBanking from "@/commons/forms/Fields/OpenBanking/PzOpenBanking";
import { mapGetters } from "vuex";
import { required } from "@/commons/utils/Rules";
export default {
  name: 'PzPersonFormEs2',
  components: {
    PzOpenBanking: PzOpenBanking,
    PzDirection: PzDirection,
    PzFormFlujo: PzFormFlujo
  },
  mixins: [baseFormFlujo],
  data: function data() {
    var _this = this;

    var check_data = function check_data(rule, value, callback) {
      if (!_this.formulario1.instantor.open_banking) {
        return callback(new Error(_this.$t('rules.should_validate_bank_data')));
      } else {
        return callback();
      }
    };

    return {
      reference: 'formulario1',
      genero: [{
        id: 'f',
        name: this.$t('forms_flujo.form1_persona.SP.femenino')
      }, {
        id: 'm',
        name: this.$t('forms_flujo.form1_persona.SP.masculino')
      }, {
        id: 'o',
        name: this.$t('forms_flujo.form1_persona.SP.otro')
      }],
      studies: [],
      bancos: [],
      marital_status: [],
      vnz: false,
      formulario1: {
        direccion: {},
        estado_civil_id: '',
        genero: '',
        entidad_nacimiento: '',
        banco_deposito: '',
        cuenta_deposito: '',
        instantor: {},
        info_extra: {
          carnet_conducir: ''
        },
        numero_documento: ''
      },
      rules_formulario1: {
        genero: [required('')],
        entidad_nacimiento: [required('')],
        bank_data: [{
          validator: check_data,
          trigger: 'change'
        }],
        'instantor.cuenta': [required('')]
      }
    };
  },
  computed: _objectSpread({}, mapGetters(['applicant'])),
  created: function created() {
    this.fetchData();
  },
  mounted: function mounted() {
    this.formulario1.numero_documento = this.applicant.numero_documento;
  },
  methods: {
    fetchData: function fetchData() {
      var _this2 = this;

      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var estado = getEstadoCivil().then(function (res) {
        _this2.marital_status = res.data;
      }).catch(function () {
        _this2.marital_status = [];
      });
      var bancos = getBancos().then(function (res) {
        _this2.bancos = res.data;
      }).catch(function () {
        _this2.bancos = [];
      });
      return Promise.all([estado, bancos]).then(function () {}).finally(function () {
        _this2.$store.dispatch('loader/down', {
          trigger: _this2.$options.name
        });
      });
    }
  }
};