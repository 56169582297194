var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pz-form-flujo",
    {
      attrs: {
        "available-captcha": _vm.extraData.withCaptcha,
        forward: _vm.handleSave,
        backwards: _vm.showBack ? _vm.handleBack : null
      },
      model: {
        value: _vm.formulario1["captcha"],
        callback: function($$v) {
          _vm.$set(_vm.formulario1, "captcha", $$v)
        },
        expression: "formulario1['captcha']"
      }
    },
    [
      _c("span", { attrs: { slot: "form_title" }, slot: "form_title" }, [
        _vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.UY.title")) + " ")
      ]),
      _c(
        "el-form",
        {
          ref: _vm.reference,
          attrs: {
            slot: "formulario",
            "label-position": "top",
            model: _vm.formulario1,
            "status-icon": "",
            rules: _vm.rules_formulario1
          },
          slot: "formulario"
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "nombres",
                        label: _vm.$t("forms_flujo.form1_persona.SP.nombre"),
                        error: _vm.errors.get("nombres")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "nombres",
                        staticStyle: { "text-transform": "uppercase" },
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form1_persona.SP.nombre"
                          )
                        },
                        model: {
                          value: _vm.formulario1.nombres,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario1, "nombres", $$v)
                          },
                          expression: "formulario1.nombres"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "apellidos",
                        label: _vm.$t("forms_flujo.form1_persona.SP.apellidos"),
                        error: _vm.errors.get("apellido_paterno")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "apellidos",
                        staticStyle: { "text-transform": "uppercase" },
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form1_persona.SP.apellidos"
                          )
                        },
                        model: {
                          value: _vm.formulario1.apellidos,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario1, "apellidos", $$v)
                          },
                          expression: "formulario1.apellidos"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c("pz-identity-document", {
                    attrs: {
                      prop: "identity",
                      type: "person",
                      "number-hidden": _vm.condocumento,
                      "type-label": _vm.$t(
                        "forms_flujo.form1_persona.UY.tipo_doc"
                      ),
                      "number-label": _vm.$t(
                        "forms_flujo.form1_persona.UY.identity_doc"
                      )
                    },
                    model: {
                      value: _vm.formulario1.identity,
                      callback: function($$v) {
                        _vm.$set(_vm.formulario1, "identity", $$v)
                      },
                      expression: "formulario1.identity"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "fecha_nacimiento",
                        label: _vm.$t(
                          "forms_flujo.form1_persona.UY.fecha_nacimiento"
                        ),
                        error: _vm.errors.get("fecha_nacimiento")
                      }
                    },
                    [
                      _c("el-date-picker", {
                        ref: "fecha_nacimiento",
                        attrs: {
                          type: "date",
                          format: "dd/MM/yyyy",
                          "value-format": "dd/MM/yyyy",
                          "picker-options": _vm.pickerOptions1,
                          placeholder: _vm.$t(
                            "forms_flujo.form1_persona.UY.fecha_nacimiento"
                          )
                        },
                        model: {
                          value: _vm.formulario1.fecha_nacimiento,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario1, "fecha_nacimiento", $$v)
                          },
                          expression: "formulario1.fecha_nacimiento"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "telefono",
                        label: _vm.$t("forms_flujo.form1_persona.UY.telefono"),
                        error: _vm.errors.get("telefono")
                      }
                    },
                    [
                      _c("el-input", {
                        ref: "telefono",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "forms_flujo.form1_persona.UY.telefono"
                          )
                        },
                        model: {
                          value: _vm.formulario1.telefono,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario1, "telefono", $$v)
                          },
                          expression: "formulario1.telefono"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "fecha_ingreso",
                        label: _vm.$t(
                          "forms_flujo.form1_persona.UY.fecha_ingreso"
                        ),
                        error: _vm.errors.get("fecha_ingreso")
                      }
                    },
                    [
                      _c("el-date-picker", {
                        ref: "fecha_ingreso",
                        attrs: {
                          type: "date",
                          "picker-options": _vm.pickerOptions1,
                          disabled: _vm.isjubilado,
                          format: "dd/MM/yyyy",
                          "value-format": "dd/MM/yyyy",
                          placeholder: _vm.$t(
                            "forms_flujo.form1_persona.UY.fecha_ingreso"
                          )
                        },
                        model: {
                          value: _vm.formulario1.fecha_ingreso,
                          callback: function($$v) {
                            _vm.$set(_vm.formulario1, "fecha_ingreso", $$v)
                          },
                          expression: "formulario1.fecha_ingreso"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "relacion_laboral_id",
                        label: _vm.$t(
                          "forms_flujo.form2_persona.BNF.tipo_trabajo"
                        )
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "relacion_laboral_id",
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: _vm.$t(
                              "forms_flujo.form2_persona.BNF.tipo_trabajo"
                            )
                          },
                          model: {
                            value: _vm.formulario1.relacion_laboral_id,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formulario1,
                                "relacion_laboral_id",
                                $$v
                              )
                            },
                            expression: "formulario1.relacion_laboral_id"
                          }
                        },
                        _vm._l(_vm.relacion_laboral, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.relacion,
                              value: item.id,
                              placeholder: _vm.$t(
                                "forms_flujo.form2_persona.BNF.tipo_trabajo"
                              )
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    xl: { span: 12 },
                    lg: { span: 12 },
                    md: { span: 12 },
                    sm: { span: 24 },
                    xs: { span: 24 }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "type_prestamo",
                        label: _vm.$t(
                          "forms_flujo.form1_persona.BNF.tipo_prestamo"
                        ),
                        error: _vm.errors.get("type_prestamo")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "tipo_prestamo",
                          attrs: {
                            clearable: "",
                            placeholder: _vm.$t(
                              "forms_flujo.form1_persona.BNF.tipo_prestamo"
                            )
                          },
                          on: { change: _vm.modelDialog },
                          model: {
                            value: _vm.formulario1.type_prestamo,
                            callback: function($$v) {
                              _vm.$set(_vm.formulario1, "type_prestamo", $$v)
                            },
                            expression: "formulario1.type_prestamo"
                          }
                        },
                        _vm._l(_vm.tipo_prestamo, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.name,
                              value: item.id,
                              placeholder: _vm.$t(
                                "forms_flujo.form1_persona.BNF.tipo_prestamo"
                              )
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "Información",
                width: "30%",
                visible: _vm.dialogVisible,
                "show-close": false,
                "close-on-click-modal": false,
                "close-on-press-escape": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c("span", [
                _vm._v(
                  "Esta solicitud cancelará todos sus préstamos personales de consumo vigentes"
                )
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("Aceptar")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }